import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { graphql } from "gatsby";
import PageLayout from "./../../../templates/product";
export const PageQuery_product_en_scannermdx = graphql`
        query PageQuery_product_en_scannermdx {
            # Required by gatsby-plugin-react-i18next
            locales: allLocale(filter: {language: {eq: "en"}, ns: {in: ["common", "product"]}}) {
                ...TranslationFragment
            }
            # Required by gatsby-plugin-image
            mdx(frontmatter:
                {
                    path: {regex: "/scanner/"},
                    language: {eq: "en"}
                }) {
                frontmatter {
                    ...MdxServiceFrontmatterFragment
                }
            }
        }
    `;
export const _frontmatter = {
  "title": "Recognition",
  "description": "Scan receipts & invoices",
  "path": "/product/scanner",
  "date": "2022-05-30T00:00:00.000Z",
  "template": "Product",
  "language": "en",
  "icon": "bx-barcode-reader",
  "prio": 1,
  "stage": "production",
  "hero_image": "./images/scanner/main-promotional.png",
  "tags": ["product", "scanner", "receipt", "invoice", "ai", "ocr"],
  "main": {
    "title": "Extract Details + Structures",
    "description": "Automatically recognise more data from receipts and invoices with new extraction technology. You have literally never seen this before."
  },
  "comparison": {
    "description": "Drag slider to see the differences - currently available (l) vs Cheesebyte OCR scanner (r).",
    "imageBefore": "./images/scanner/invoice-before.png",
    "imageAfter": "./images/scanner/invoice-after.png"
  },
  "feature": {
    "title": "Save Time And Money",
    "description": "Stop worrying about missing information and the time it takes to manually adjust text from unstructured source material."
  },
  "usp": [{
    "title": "Machine Learning",
    "description": "Applies latest developments in machine learning to extract details quickly.",
    "icon": "bx-cog"
  }, {
    "title": "Data Security",
    "description": "Encryption keeps your data safe, images are deleted after processing.",
    "icon": "bx-check-shield"
  }, {
    "title": "Automatic Detection",
    "description": "Build structured details for product line, VAT and payment info; no adjustments required.",
    "icon": "bx-message-alt-detail"
  }, {
    "title": "Excellent Support",
    "description": "Support via email, chat or phone (depending on the plan chosen) is just one step away.",
    "icon": "bx-user-voice"
  }, {
    "title": "Effective Scanning",
    "description": "Go far beyond the 40 to 80% promised by other providers and extract more structure.",
    "icon": "bx-barcode-reader"
  }, {
    "title": "Image Quality",
    "description": "As long as the input image can be read by a human, this service can do it as well.",
    "icon": "bx-image-alt"
  }],
  "faq": [{
    "title": "Why should I use this service?",
    "body": "Because it allows you to skip manual steps during your data processing. It automates steps towards the goal of acquiring structural data from unstructured sources and makes the process \"plug and play\"."
  }, {
    "title": "How does it compare to existing software?",
    "body": "Competing services usually depend on finding meaning in a big soup of characters by using regular expressions and by offering (with an additional cost) extra solutions for improved analysis."
  }, {
    "title": "Is my country or language supported?",
    "body": "Initially, English and Dutch are best supported. Guaruanteed support for additional languages will be worked on according to priorities that arise from the paid plans."
  }, {
    "title": "How do you ensure that my data is secure?",
    "body": "Input data is secured with AES-256 encryption and transfered via a safe connection. On the server, input data is deleted immediately after it has been processed. Sensitive details are processed independently for extra security."
  }],
  "plans": [{
    "id": "0dcd9975-daa0-4fd7-9eb7-7cb70b882419",
    "name": "Basic",
    "feature": [{
      "active": true,
      "description": "For personal projects or low usage"
    }, {
      "active": false,
      "description": "Rate-limited to 1 scan per 60 sec"
    }, {
      "active": false,
      "description": "Lower priority in queue"
    }, {
      "active": false,
      "description": "Support via email only"
    }]
  }, {
    "id": "f7fe4386-f87e-4efe-9a33-a7a05c0b1e54",
    "name": "Advanced",
    "feature": [{
      "active": true,
      "description": "For small business owners"
    }, {
      "active": true,
      "description": "High priority in queue"
    }, {
      "active": true,
      "description": "Up to 99% accuracy (EN + NL)"
    }, {
      "active": false,
      "description": "Support via email and chat only"
    }]
  }, {
    "id": "cdbe1afe-f17a-4f30-8d5d-466cbc154227",
    "name": "Expert",
    "feature": [{
      "active": true,
      "description": "For larger businesses"
    }, {
      "active": true,
      "description": "Adds detailed meta info per scan"
    }, {
      "active": true,
      "description": "Extended management functions"
    }, {
      "active": true,
      "description": "Support via email, chat and phone"
    }]
  }]
};
const layoutProps = {
  PageQuery_product_en_scannermdx,
  _frontmatter
};
const MDXLayout = PageLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      